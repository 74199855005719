import { Trans, useTranslation } from 'react-i18next'
import React, { useContext } from 'react'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'

export const Error403 = () => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)

  return (
    <div className="w-full flex flex-col items-center h-full">
      <div className="mt-[40px] text-gray-1350 text-xl dark:text-gray-1250 text-center">
        <Trans i18nKey="error403" />
      </div>
    </div>
  )
}
