import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import { CurrentHeaderContext } from 'core/contexts/CurrentHeaderContext'

export const LayoutSubHeader = () => {
  const { header } = useContext(CurrentHeaderContext)
  const { isDarkTheme } = useContext(DarkThemeContext)
  const { t } = useTranslation()

  const svgPath = require(`core/assets/images/logos/${header.svgPath}`)
  const svgPathDark = require(`core/assets/images/logos/${header.svgPathDark}`)

  return (
    <div className="sub-header">
      <div
        className={`flex ${header.column ? 'flex-col' : 'flex-row gap-x-2'} items-center justify-center h-full`}
      >
        <img
          src={isDarkTheme ? svgPathDark : svgPath}
          alt="Icon"
          className={`${header.column} ?'h-[50px]' : 'h-[40px]'`}
        />
        <div className="font-light">{t(header.accroche)}</div>
      </div>
    </div>
  )
}
