import React from 'react'

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { UseKeycloakService } from 'core/packages/KeycloakProvider'
import Loader from 'core/components/common/Loader'
import { Provider } from 'react-redux'
import ErrorHandler from 'core/components/common/ErrorHandler'
import { Layout } from 'core/pages/Layout'
import 'core/assets/styles/style.css'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { Error500 } from 'core/components/common/Error500'
import { Error404 } from 'core/components/common/Error404'
import { LayoutApp } from 'core/components/layout/LayoutApp'
import { IaDocs } from 'iadocs/pages/IaDocs'
import { ExtractPage } from 'iadocs/pages/ExtractPage'
import { UnbundlePage } from 'iadocs/pages/UnbundlePage'
import { TypingPage } from 'iadocs/pages/TypingPage'
import store from 'core/store'
import ExternalRedirect from 'core/components/common/ExternalRedirect'
import { URL_IASTART } from 'core/constants/general'
import { Error403 } from 'core/components/common/Error403'

function App() {
  const kc = UseKeycloakService()
  const isAuthorized = kc.checkRole('iadocs:user')
  const isLoading = !kc.isInitialized()
  const {
    IADOCS,
    ERROR,
    IADOCS_EXTRACT,
    IADOCS_TYPING,
    IADOCS_UNBUNDLE,
    IASTART,
  } = ROUTE_PATHS
  if (isLoading) {
    return (
      <Loader center className="h-screen flex items-center justify-center" />
    )
  }

  return (
    <Provider store={store}>
      <ErrorHandler>
        <BrowserRouter>
          <Routes>
            <Route
              path={IASTART}
              element={<ExternalRedirect url={URL_IASTART} />}
            />
            <Route path="" element={<Layout />}>
              <Route path="" element={<LayoutApp />}>
                <Route path="/" element={<Navigate to={IADOCS} replace />} />
                {isAuthorized ? (
                  <>
                    <Route path={IADOCS} element={<IaDocs />} />
                    <Route path={IADOCS_EXTRACT} element={<ExtractPage />} />
                    <Route path={IADOCS_TYPING} element={<TypingPage />} />
                    <Route path={IADOCS_UNBUNDLE} element={<UnbundlePage />} />
                  </>
                ) : (
                  <Route path="*" element={<Error403 />} />
                )}
              </Route>
            </Route>
            <Route path="" element={<Layout itemsCenter />}>
              <Route path={ERROR} element={<Error500 />} />
              <Route path="403" element={<Error403 />} />
              <Route path="*" element={<Error404 />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ErrorHandler>
    </Provider>
  )
}

export default App
